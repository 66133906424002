import AccessControlSpecsPage from "components/access-control/specs/AccessControlSpecsPage";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import Helmet from "react-helmet";

export default function SecureCards() {
  const { isMobile } = useIsMobile(700);
  let exploreDR40Width = "51px";
  let exploreDR20Width = "48px";

  if (isMobile) {
    exploreDR40Width = "41px";
    exploreDR20Width = "38px";
  }

  const renderContent = data => {
    const secureCards = {
      title: "Secure Card",
      imageHeader: data.secureCardsHeader,
      imageHeaderMobile: data.secureCardsHeaderMobile,
      objectPosition: isMobile ? "center" : "top",
      description:
        "Give users a High Frequency (13.56 MHz) Smart Card with advanced authentication and encryption features.",
      image: data.secureCardsDims,
      pdf: "/files/specs/secure-card.pdf",
      manual: "/files/manuals/DC20-Manual.pdf",
      column1: [
        {
          title: "Dimensions",
          text: ["85.6mm (3.37in) x 54.0mm (2.13in) x 0.76mm (0.033in)"],
        },
        {
          title: "Weight",
          text: ["0.15oz (4.3g)"],
        },
        {
          title: "Material",
          text: "PVC",
        },
        {
          title: "Color",
          text: "White",
        },
        {
          title: "Surface Finish",
          text: ["Gloss"],
        },
        {
          title: "Operating Temperature",
          text: "-20˚C to 50˚C (-4˚F to 122˚F)",
        },
        {
          title: "Product Quality",
          text: "Per ISO/IEC 7810",
        },
        {
          title: "IC",
          text: "MIFARE DESFire EV1",
        },
        {
          title: "Protocol",
          text: "ISO/IEC 14443A",
        },
      ],
      column2: [
        {
          title: "Operating Frequency",
          text: "13.56MHz",
        },
        {
          title: "Encryption",
          text: "128bit AES",
        },
        {
          title: "Memory",
          text: "2KB",
        },
        {
          title: "UID",
          text: "7B",
        },
        {
          title: "Data Retention",
          text: "10 Years",
        },
        {
          title: "Write Cycles",
          text: "100,000",
        },
        {
          title: "Quantity",
          text: "100 cards per box",
        },
        {
          title: "Box Dimensions",
          text: "114mm (4.5in) x 104mm (4.1in) x 79mm (3.1in)",
        },
        {
          title: "Shipping Weight",
          text: "0.6kg (1.28lbs)",
        },
      ],
      exploreCards: [
        {
          image: data.exploreDR20,
          alt: "DR20 Access Control Door Reader",
          width: exploreDR20Width,
          model: "DR20",
          useCase: "Modern, touchless, and reliable smart reader",
          link: "/access-control/door-readers/dr20/",
        },
        {
          image: data.exploreDR40,
          alt: "DR40 Access Control Door Reader",
          width: exploreDR40Width,
          model: "DR40",
          useCase: "Equipped with a door reader, camera, and intercom",
          link: "/access-control/door-readers/dr40/",
        },
      ],
    };

    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus Secure Cards</title>
          <meta name="description" content="" />
        </Helmet>
        <AccessControlSpecsPage model={secureCards} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      secureCardsHeader: file(
        relativePath: {
          eq: "components/access-control/specs/img/secure-cards-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      secureCardsHeaderMobile: file(
        relativePath: {
          eq: "components/access-control/specs/img/secure-cards-hero-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      secureCardsDims: file(
        relativePath: {
          eq: "components/access-control/specs/img/secure-card-dimensions.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR20: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr20.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR40: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr40.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
